
import PH1 from '../images/PHorde2.png'
import PH2 from '../images/PHorde3gold.png'
import React, { useEffect, useState } from 'react';
import * as api from '../api/calls'
import { List, ListItem, makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import OrderServer from './OrderServer';

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    paper: {
        height: 140,
        width: 100,
    },
    marginAutoItem: {
        margin: 'auto',
        display: 'flex',
    },
    prodImg: {
        maxHeight: '50vh'
    },
    prodIcon: {
        maxHeight: '10vh',
        maxWidth: '10vw'
    },
    bigImg: {
        maxHeight: '75vh'
    },
    bigTitle: {
        textDecoration: 'underline',
    },
    descTxt: {
        fontFamily: 'Roboto',
        fontSize: '0.875rem'
    },
    price: {
        margin: 'auto'
    },
    justified: {
        textAlign: 'center',
        justifyContent: 'center',
        padding:'0.5rem 0.1rem 0.1rem 0.1rem'
    },
    action: {
        textAlign: 'center',
        justifyContent: 'center',
        padding:'1rem'
    }
})

function ProductRow({ productObj, showDescription=false }) {

    const classes = useStyles()

    return (
        <Box pt={1} pb={1}>
            {productObj && <Box>
                <Typography variant="h5">{productObj.name}</Typography>
                {showDescription && <Typography className={classes.descTxt}>{productObj.description}</Typography>}
            </Box>
            }
            <Grid container className={classes.root} spacing={2}>
                {productObj ? productObj.prices.map(price => (
                    <Grid item xs={4} key={`prod${price.id}`}>
                        {/* <Grid container justifyContent="center" spacing={2}> */}
                        <Card >
                                <Box pt={1} >
                                    <img className={`${classes.prodIcon} ${classes.marginAutoItem}`} src={productObj.images[0]}></img>
                                </Box>
                                <CardContent className={classes.justified}>
                                    <Typography variant="body2">
                                        {productObj.name}
                                    </Typography>
                                    <Box pt={1} className={classes.justified}>
                                        <Typography variant="body2">
                                            {price.priceTxt}
                                        </Typography>
                                    </Box>

                                </CardContent>
                            <CardActions className={classes.action}>
                                <Box >
                                    <OrderServer product={productObj} priceID={price.id}></OrderServer>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                )) : 'Loading Products...'}
            </Grid>
        </Box>
    )
}

export default function ProductList() {

    const classes = useStyles()

    const [productsJSON, setProductsJSON] = useState([])

    const getProducts = async () => {
        const prodResponseData = await api.getProducts()
        setProductsJSON(prodResponseData)
    }

    useEffect(() => {
        getProducts()
    }, [])

    const productPCName = `Pavlov VR (PC) Server`
    const productShackName = `Pavlov Shack (Quest) Server`
    const productShackProName = `Pavlov Shack (Quest) Server PRO`
    const productPC = productsJSON && productsJSON.find(x => x.name == productPCName)
    const productShack = productsJSON && productsJSON.find(x => x.name == productShackName)
    const productShackPro = productsJSON && productsJSON.find(x => x.name == productShackProName)

    return (
        <Box>
            <ProductRow productObj={productShack} showDescription/>
            <ProductRow productObj={productShackPro} showDescription/>

            {/* <ProductRow productObj={productPC} titleTxt={`${PCTxt} Servers:`}/> */}
        </Box>
        
    )
}
