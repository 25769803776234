import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, List, ListItem, makeStyles, Select, TextField, Typography, Checkbox } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { LogInOrSignUp } from '../components/SignUp'
import ProductList from '../components/ProductList'
import { SingleSelect } from './formControls'
import * as api from '../api/calls'
import { Link, navigate } from 'gatsby'
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as myRegex from '../data/regex.json'
import ContactUsButton from './Contact'

const stripePromise = loadStripe("pk_live_51I6enLBZssIfsE8Ka9tW2B9YGB51J37CvpgafkOnC9UmvICJYjDl8s0QYRXluRK7FivSFHjzmqlNjTakcMPkOYo800SGDPITwT");

const useStyles = makeStyles({
    promptTxt: {
        fontFamily: 'Roboto',
        fontSize: '0.75em'
    },
    spaced: {
        justifyContent: "space-evenly"
    },
    alert: {
        fontFamily: 'Roboto',
        fontSize: '1em',
        color: 'red'
    },
    alertBig: {
        fontSize: '1em',
        color: 'red'
    },
    minibutton: {
        fontSize: '0.75rem'
    }
})

export default function OrderServer({ product, priceID }) {

    let userDetails
    if (typeof window !== `undefined`) userDetails = JSON.parse(window.localStorage.getItem('userDetails'))

    const [open, setOpen] = useState(false)
    const [hasLoggedIn, setHasLoggedIn] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    const handleLoggedIn = () => {
        setHasLoggedIn(true)
    }

    return (
        <Box>
            {open ?
                userDetails || hasLoggedIn ?
                    <Elements stripe={stripePromise}>
                        <OrderForm setOpen={setOpen} open={open} defaultDetails={userDetails} product={product} priceID={priceID} />
                    </Elements> :
                    <LogInOrSignUp autoOpen={open} setIsLoggedIn={handleLoggedIn} /> : ''}
            <Button variant="contained" color="secondary" onClick={handleClick}>Order Server</Button>
        </Box>
    )
}


function OrderForm({ open, setOpen, defaultDetails, product, priceID }) {

    const stripe = useStripe()
    const classes = useStyles()
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [orderDetails, setOrder] = useState({
        servername: defaultDetails.user_name + " Server",
        email: defaultDetails.user_detail.email,
        rconpass: '',
        region: {
            city: "Amsterdam",
            id: "ams"
        }
    })

    const [regions, setRegions] = useState()

    const [RCONPError, setRCONPError] = useState(false)

    const getRegions = async () => {
        const res = await api.getRegions(product.id)
        const regionsList = res.data.regions.map(x => ({ id: x.id, city: x.city }))
        setRegions(regionsList)
    }

    const handlePingCheck = () => {
        if (typeof window !== `undefined`) window.open('https://cloudpingtest.com/vultr')
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleRCONPass = (event) => {
        const whiteSpaceR = new RegExp(myRegex.whitespace)
        const alphaNumericR = new RegExp(myRegex.alphanumeric)
        const passTxt = event.target.value
        const isError = !alphaNumericR.test(passTxt)
        setRCONPError(isError)
        handleOrder(event)
    }

    const handleOrder = (event) => {
        setOrder({ ...orderDetails, [event.target.id]: event.target.value })
    }

    const handleLocation = (value) => {
        const thisRegion = regions && regions.find(x => x.city == value)
        setOrder({ ...orderDetails, "region": thisRegion })
    }

    const handleOrderSubmit = async () => {
        const { servername, email, rconpass, region } = orderDetails

        if (servername && email && rconpass && region) {
            try {
                setLoading(true)
              // console.log('Order:', orderDetails)
                const customerID = defaultDetails.user_detail.stripeCustomerID
                const metadata = { servername, rconpass, region: region.id, username: defaultDetails.user_name }
                const session = await api.createCheckoutSession(customerID, priceID, orderDetails.email, metadata)
                const orderResult = await stripe.redirectToCheckout({
                    sessionId: session.data.sessionId
                })
                setLoading(false)
                if (orderResult.error) {
                    // Show error to your customer (e.g., insufficient funds)
                  // console.log(orderResult.error.message);
                    setError(JSON.stringify(orderResult.error.message))
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {

                        // Show a success message to your customer
                        // There's a risk of the customer closing the window before callback
                        // execution. Set up a webhook or plugin to listen for the
                        // payment_intent.succeeded event that handles any business critical
                        // post-payment actions.
                        navigate('/horde')
                        setOpen(false)
                    }
                }

            } catch (e) {
                console.log(e)
                setError(JSON.stringify(e.message))
                setLoading(false)
            }
        } else {
            setError('Please fill all fields')
        }
    }

    useEffect(() => {
        getRegions()
    }, [])

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>{`Config ${product.name}`}</DialogTitle>
                <Box>
                    <List>
                        <ListItem >
                            <TextField
                                id="email"
                                label="Email Address"
                                type="email"
                                onChange={handleOrder}
                                value={orderDetails.email}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                id="servername"
                                label="Server Name"
                                type="text"
                                onChange={handleOrder}
                                value={orderDetails.servername}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                id="rconpass"
                                label={RCONPError ? "Please use only letters and numbers" : "RCON Password"}
                                type="text"
                                onChange={handleRCONPass}
                                value={orderDetails.rconpass}
                                error={RCONPError}
                            />
                        </ListItem>
                        <ListItem>

                            <SingleSelect
                                title="Region"
                                setValue={handleLocation}
                                data={regions && regions.map(x => x.city)}
                                value={orderDetails.region && orderDetails.region.city} />
                            <Box pl={2}>
                                <Button className={classes.minibutton} variant="outlined" onClick={handlePingCheck}>Check<br></br>Pings</Button>
                            </Box>
                        </ListItem>
                    </List>
                    {error && <Typography className={classes.alert}>{error}</Typography>}
                    <DialogActions className={classes.spaced}>
                        <Button onClick={handleClose}>Cancel</Button>
                        {isLoading ? <CircularProgress /> :
                            RCONPError ? <Typography className={classes.alert}>Please fix errors above</Typography> :

                                <Button variant="contained" color="secondary" onClick={handleOrderSubmit}>Order Server</Button>

                        }
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    )
}