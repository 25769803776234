import { Box, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { navigate } from "gatsby"
import React, { useState } from 'react';
import HordeManager from '../components/HordeAccount'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Router } from "@reach/router"
import Description from '../components/Products'
import LogIn from '../components/SignUp';
import OrderServer from '../components/OrderServer'
import ProductList from '../components/ProductList'
import Contact from '../components/Contact';

const ProductsPage = ({ location }) => {

  const handleBack = () => {
    navigate("/")
  }

  const pageTitle = "Horde Servers"

  const pageDesc = "Order a Horde Pavlov Server"

  return (
    <Layout pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <IconButton
        onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h3" style={{ color: "white" }}>
        {pageDesc}
      </Typography>
      <Box display="flex" justifyContent="space-evenly">
        <Contact />
      </Box>
      <Box display="flex" justifyContent="space-evenly">
        <ProductList />

      </Box>
      

      <Description></Description>

    </Layout>
  )
}

export default ProductsPage
